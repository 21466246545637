<template>
    <div class="p-3">
        <h2>Kontakti</h2>
        <br>
        <h3>Podjetje</h3>
        SMRK, Mojca Tomišić, s.p., spletna tržnica
        <br>
        Stanetinci 7
        <br>
        9244 Sv. Jurij ob Ščavnici
        <br><br>
        <b>ID za DDV:</b> 35592303
        <br>
        <b>Zavezanec za DDV:</b> Ne
        <br>
        <b>Matična številka:</b> 8263973000
        <br><br>
        Registracija: AJPES, izpostava Murska Sobota: št. vpisa 316-12-02695-2018/2, datum vpisa: 3. 9. 2018.
        <br>
        Evidenca Registriranih obratov nosilcev živilske dejavnosti, ki jo vodi Uprava Rapublike Slovenije za varno hrano, veterinarstvo in varstvo rastlin, Območni urad Murska Sobota: št. vpisa U33200-290/2018-2(U951-16).
        <br><br>
        <b>IBAN (TRR):</b> SI56 6100 0002 0218 639
        <br>
        <b>Banka:</b> Delavska hranilnica d.d.
        <br>
        <b>BIC banke:</b> HDELSI22
        <br><br>
        <h3>Kontakt za informacije in pomoč uporabnikom</h3>
        <b>E-pošta:</b> info@smrk.si
        <br>
        <b>Telefon:</b> 040 612-870



    </div>
</template>

<script lang="">
export default {
name: "Contacts",
        components: {},
        props: {},
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}


</script>

<style scoped>

</style>
